<template>
  <v-layout column class="mb-2">
    <v-layout align-center>
      <slot name="header-items"></slot>
    </v-layout>
    <v-scroll-y-transition>
      <v-textarea
        ref="lta"
        dense
        v-if="!collapsed"
        :label="label"
        outlined
        required
        :rules="rules"
        v-model="text"
        class="default-input-style mx-4"
        :disabled="disabled"
        :readonly="readOnly"
        :rows="rows"
        :hide-details="hideDetails"
        clear-icon="mdi-close"
        auto-grow
      >
        <template v-if="autosave || showAppend" slot="append">
          <slot name="append"
            ><AutosaveIcon :value="status"></AutosaveIcon
          ></slot>
        </template>
      </v-textarea>
    </v-scroll-y-transition>
    <slot name="footer-items"></slot>
  </v-layout>
</template>

<script>
import ModificationMixin from "@/mixins/ModificationMixin";

export default {
  mixins: [ModificationMixin],
  props: {
    value: {},
    label: {},
    placeholder: {},
    disabled: {
      default: false,
    },
    rules: {},
    readOnly: {},
    collapsed: {
      default: false,
    },
    hideDetails: {
      default: false,
    },
    rows: {
      default: 3,
    },
    required: {
      type: Boolean,
      default: false,
    },
    showAppend: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    AutosaveIcon: () => import("./AutosaveIcon.vue"),
  },
  computed: {
    text: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("update:value", value);
      },
    },
  },
  methods: {
    focus() {
      this.$refs.lta.focus();
    }
  },
  mounted() {
    if (this.autosave) {
      this.startWatching("text");
    }
  },
};
</script>
